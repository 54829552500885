import React from "react";
import { Link } from "react-router-dom";

function Slider() {
  return (
    <section className="slider">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="slider-content-main">
              <ol>
                <li>
                  <i className="fas fa-shield-alt"></i>
                </li>
              </ol>
              <h2>Finest Defence Lawyers</h2>
              <p>
                business<span>-</span>politics<span>-</span>Breaking
                <span>-</span>featured<span>-</span>tech<span>-</span>article
              </p>
              <div className="btn-01">
                <Link to="/contactus">contact us now</Link>
                <a href="#">see & learn more</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Slider;
