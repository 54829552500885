import React from "react";

function Business() {
  return (
    <section className="bg-01">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="bg-main">
              <i className="fas fa-landmark"></i>
              <h2>$325,000</h2>
              <b>Recoverd to clients</b>
              <p>
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <a href="#">Contact us now</a>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="bg-main bg-sub">
              <i className="fas fa-landmark"></i>
              <h2>$325,000</h2>
              <b>Recoverd to clients</b>
              <p>
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <a href="#">Contact us now</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Business;
