import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header>
      <div className="top-nav">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="logo">
                <h1>lawyer</h1>
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="top-sec-main">
                <div className="top-sec">
                  <ol>
                    <li>
                      <i className="far fa-clock"></i>
                      <span>open on mon-fri</span>8:00-19:00
                    </li>
                    <li>
                      <i className="fa fa-headphones"></i>
                      <span>call for consultation</span>123.456.789
                    </li>
                  </ol>
                </div>
                <div className="top-sec-2">
                  <ol>
                    <li>
                      <i className="fab fa-facebook-square"></i>
                    </li>
                    <li>
                      <i className="fab fa-twitter"></i>
                    </li>
                    <li className="last">
                      <i className="fas fa-search"></i>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="_main_nav">
        <div className="container">
          <div className="row">
            <div className="nav">
              <div className="logo-01">
                <h1>lawyer</h1>
              </div>
              <div className="menu-toggle"></div>
              <div className="my-nav">
                <div className="menu">
                  <ul>
                    <li>
                      <Link to="/home">Home</Link>
                    </li>
                    <li>
                      <Link to="/aboutus">About Us</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/contactus">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
