import React from "react";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
            <div className="footer-content">
              <p>
                publishing software like Aldus PageMaker Lorem Ipsum publishing
                software like Aldus PageMaker Lorem Ipsum
              </p>
              <ol>
                <li>
                  <i className="fab fa-facebook-square"></i>
                </li>
                <li>
                  <i className="fab fa-pinterest"></i>
                </li>
                <li>
                  <i className="fab fa-instagram"></i>
                </li>
                <li>
                  <i className="fab fa-twitter"></i>
                </li>
              </ol>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
            <div className="footer-content">
              <h2>recent comments</h2>
              <ul>
                <li>publishing software like Aldus PageMaker Lorem Ipsum</li>
                <li>publishing software like Aldus PageMaker Lorem Ipsum</li>
                <li>publishing software like Aldus PageMaker Lorem Ipsum</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
            <div className="footer-content">
              <h2>Archives</h2>
              <ul>
                <li>October 2018</li>
                <li>september 2018</li>
                <li>August 2018</li>
                <li>july 2018</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-sm-6 col-xs-6">
            <div className="footer-content">
              <h2>Aditional links</h2>
              <ul>
                <li>home</li>
                <li>blog</li>
                <li>about us</li>
                <li>categories</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
