import React from "react";

function Other() {
  return (
    <>
      <section className="bg-02">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="headding-01">
                <i className="fas fa-landmark"></i>
                <h2>Toughest Defense Lawyers For Your Money</h2>
                <p>
                  publishing software like Aldus PageMaker including versions of
                  Lorem Ipsum.publishing software like Aldus PageMaker Lorem
                  Ipsum.publishing software like
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="big-client">
                <div className="client">
                  <img src="assets/images/client-01.jpg" />
                </div>
                <div className="client-content">
                  <h2>John Appleseed</h2>
                  <p>External Relations</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="big-client">
                <div className="client">
                  <img src="assets/images/client-02.jpg" />
                </div>
                <div className="client-content">
                  <h2>Rose johnson</h2>
                  <p>External Relations</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="big-client">
                <div className="client">
                  <img src="assets/images/client-03.jpg" />
                </div>
                <div className="client-content">
                  <h2>Albert</h2>
                  <p>External Relations</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="big-client">
                <div className="client">
                  <img src="assets/images/client-04.jpg" />
                </div>
                <div className="client-content">
                  <h2>Daniel</h2>
                  <p>External Relations</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-03">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="box-01">
                <ol>
                  <li>
                    <i className="fas fa-book"></i>
                    <span>Procurement</span>publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum.publishing
                    software like Aldus PageMaker Lorem Ipsum.
                  </li>
                  <li>
                    <i className="fab fa-critical-role"></i>
                    <span>Criminal law</span>publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum.
                  </li>
                  <li>
                    <i className="far fa-hospital"></i>
                    <span>capital market</span>publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum.
                  </li>
                </ol>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="box-01">
                <ol>
                  <li>
                    <i className="fas fa-cog"></i>
                    <span>Maintenance</span>publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum.publishing
                    software like Aldus PageMaker Lorem Ipsum.
                  </li>
                  <li>
                    <i className="fas fa-landmark"></i>
                    <span>estate planning</span>publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum.
                  </li>
                  <li>
                    <i className="far fa-hand-spock"></i>
                    <span>malpractice</span>publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum.
                  </li>
                </ol>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="box-01">
                <ol>
                  <li>
                    <i className="fas fa-starfighter-alt"></i>
                    <span>family law</span>publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum.publishing
                    software like Aldus PageMaker Lorem Ipsum.
                  </li>
                  <li>
                    <i className="fab fa-critical-role"></i>
                    <span>intelectual property</span>publishing software like
                    Aldus PageMaker including versions of Lorem Ipsum.
                  </li>
                  <li>
                    <i className="fas fa-folder"></i>
                    <span>privatization</span>publishing software like Aldus
                    PageMaker including versions of Lorem Ipsum.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-04">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="box-02">
                <h2>read our blog or follow us on social media</h2>
                <p>
                  publishing software like Aldus PageMaker including versions of
                  Lorem Ipsum.publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum.
                </p>
                <a href="#">contact us now</a>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-12">
              <div className="box-02 box-02-sup">
                <ol>
                  <li>
                    publishing software like Aldus PageMaker including versions
                    of Lorem Ipsum.publishing
                    <span>October 15,2018-business</span>
                    <i className="fas fa-chevron-right"></i>
                  </li>

                  <li>
                    publishing software like Aldus PageMaker including versions
                    of Lorem Ipsum.publishing
                    <span>October 15,2018-business</span>
                    <i className="fas fa-chevron-right"></i>
                  </li>

                  <li>
                    publishing software like Aldus PageMaker including versions
                    of Lorem Ipsum.publishing
                    <span>October 15,2018-business</span>
                    <i className="fas fa-chevron-right"></i>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-02-b">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-6">
              <div className="cal-box">
                <div className="cal-content">
                  <i className="fas fa-gavel"></i>
                  <h2>1500</h2>
                  <h3>Qualified Lawyers</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6">
              <div className="cal-box">
                <div className="cal-content">
                  <i className="fas fa-mug-hot"></i>
                  <h2>600</h2>
                  <h3>Trusted Clients</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6">
              <div className="cal-box">
                <div className="cal-content">
                  <i className="fas fa-clipboard-check"></i>
                  <h2>2344</h2>
                  <h3>Successful Cases</h3>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6">
              <div className="cal-box">
                <div className="cal-content">
                  <i className="fas fa-medal"></i>
                  <h2>400</h2>
                  <h3>Honors & Awards</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Other;
