import React from "react";

function Services() {
  return (
    <>
      <section className="bg-02-a">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="_head_01">
                <h2>Services</h2>
                <p>
                  Home<i className="fas fa-angle-right"></i>
                  <span>Services</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-02-b">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="head-001">
                <h2>Services Lawyer</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laborisnisi.
                </p>
              </div>
            </div>
          </div>
          <div className="row my_row_y">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_jh_oi_cs">
                <i className="fal fa-globe"></i>
                <h2>Business law</h2>
                <p>
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laborisnisi.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_jh_oi_cs">
                <i className="fal fa-landmark"></i>
                <h2>Civil Law</h2>
                <p>
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laborisnisi.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_jh_oi_cs">
                <i className="fal fa-axe"></i>
                <h2>Criminal Law</h2>
                <p>
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laborisnisi.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_jh_oi_cs">
                <i className="fas fa-briefcase"></i>
                <h2>Business Law</h2>
                <p>
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laborisnisi.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_jh_oi_cs">
                <i className="fal fa-book-open"></i>
                <h2>Education Law</h2>
                <p>
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laborisnisi.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_jh_oi_cs">
                <i className="fal fa-sign"></i>
                <h2>Real Estate law</h2>
                <p>
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation ullamco laborisnisi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Services;
