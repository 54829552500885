import React from "react";

function AboutLawyer() {
  return (
    <section className="bg-02-b">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="head-001">
              <h2>About Us Lawyer</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco
                laborisnisi.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="_do_er_xs">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehen derit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur.
              </p>
              <p>
                We are qualified for the success of our client & as a
                professional law firm. So these professional attorneys are still
                here at your side with kindness
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation they ullamco.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 ">
            <div className="_do_er_xs">
              <div className="_kl_de_s">
                <img src="assets/images/bg/abt-01.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutLawyer;
