import React, { useState } from "react";

function ContactUs() {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  let name, value;
  const setContactData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setContact({ ...contact, [name]: value });
  };
  const submitData = async (event) => {
    event.preventDefault();
    const { name, email, subject, phone, message } = contact;
    if (name && email && subject && phone && message) {
      const res = fetch(
        "https://lawyer-001-default-rtdb.firebaseio.com/contactUsRecords.json",
        {
          method: "POST",
          headers: {
            "Constent-Type": "application/json",
          },
          body: JSON.stringify({ name, email, subject, phone, message }),
        }
      );
      if (res) {
        alert("data saved.");
        setContact({
          name: "",
          email: "",
          subject: "",
          phone: "",
          message: "",
        });
      } else {
        alert("please fill data.");
      }
    } else {
      alert("please fill data.");
    }
  };
  return (
    <>
      <section className="bg-02-a">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="_head_01">
                <h2>Contact Us</h2>
                <p>
                  Home<i className="fas fa-angle-right"></i>
                  <span>Contact Us</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-02-b contact-01">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="head-001">
                <h2>Get In Touch</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laborisnisi.
                </p>
              </div>
            </div>
          </div>
          <div className="row my_row_y mar-01">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-group">
                  <input
                    type="name"
                    name="name"
                    value={contact.name}
                    onChange={setContactData}
                    placeholder="Enter Your Name"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    value={contact.email}
                    onChange={setContactData}
                    placeholder="Enter Your Email"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-group">
                  <input
                    type="phone"
                    name="phone"
                    value={contact.phone}
                    onChange={setContactData}
                    placeholder="Phone Number"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="form-group">
                  <input
                    type="text"
                    name="subject"
                    value={contact.subject}
                    onChange={setContactData}
                    placeholder="Subject"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <textarea
                    name="message"
                    cols="30"
                    rows="8"
                    required=""
                    data-error="Write your message"
                    value={contact.message}
                    onChange={setContactData}
                    placeholder="Case Description"
                    className="form-control"
                  ></textarea>
                </div>
              </div>

              <div className="col-12">
                <div className="btn-001">
                  <a href="#" onClick={submitData}>
                    Submit
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUs;
