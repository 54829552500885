import React from "react";

function Blog() {
  return (
    <>
      <section className="bg-02-a">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="_head_01">
                <h2>Our Blog</h2>
                <p>
                  Home<i className="fas fa-angle-right"></i>
                  <span>Our Blog</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-02-b">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="head-001">
                <h2>Our Blog</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laborisnisi.
                </p>
              </div>
            </div>
          </div>
          <div className="row my_row_y">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_cd_xs_we">
                <div className="_ol_xs_we">
                  <img src="assets/images/blog/1.jpg" />
                </div>
                <div className="_lo_de_we_xa">
                  <h2> Innocent</h2>
                  <ol>
                    <li>
                      <i className="fal fa-calendar-alt"></i>10-7-2020
                    </li>
                    <li>
                      <i className="far fa-user"></i>Martin
                    </li>
                  </ol>
                  <p>
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laborisnisi.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_cd_xs_we">
                <div className="_ol_xs_we">
                  <img src="assets/images/blog/2.jpg" />
                </div>
                <div className="_lo_de_we_xa">
                  <h2>Being Loss?</h2>
                  <ol>
                    <li>
                      <i className="fal fa-calendar-alt"></i>10-7-2020
                    </li>
                    <li>
                      <i className="far fa-user"></i>Martin
                    </li>
                  </ol>
                  <p>
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laborisnisi.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_cd_xs_we">
                <div className="_ol_xs_we">
                  <img src="assets/images/blog/3.jpg" />
                </div>
                <div className="_lo_de_we_xa">
                  <h2>Car Accident?</h2>
                  <ol>
                    <li>
                      <i className="fal fa-calendar-alt"></i>10-7-2020
                    </li>
                    <li>
                      <i className="far fa-user"></i>Martin
                    </li>
                  </ol>
                  <p>
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laborisnisi.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_cd_xs_we">
                <div className="_ol_xs_we">
                  <img src="assets/images/blog/4.jpg" />
                </div>
                <div className="_lo_de_we_xa">
                  <h2>Doing Crime Day By Day?</h2>
                  <ol>
                    <li>
                      <i className="fal fa-calendar-alt"></i>10-7-2020
                    </li>
                    <li>
                      <i className="far fa-user"></i>Martin
                    </li>
                  </ol>
                  <p>
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laborisnisi.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_cd_xs_we">
                <div className="_ol_xs_we">
                  <img src="assets/images/blog/5.jpg" />
                </div>
                <div className="_lo_de_we_xa">
                  <h2>Increasing In Our Society Now?</h2>
                  <ol>
                    <li>
                      <i className="fal fa-calendar-alt"></i>10-7-2020
                    </li>
                    <li>
                      <i className="far fa-user"></i>Martin
                    </li>
                  </ol>
                  <p>
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laborisnisi.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_cd_xs_we">
                <div className="_ol_xs_we">
                  <img src="assets/images/blog/6.jpg" />
                </div>
                <div className="_lo_de_we_xa">
                  <h2>Government Prevent These Crimes?</h2>
                  <ol>
                    <li>
                      <i className="fal fa-calendar-alt"></i>10-7-2020
                    </li>
                    <li>
                      <i className="far fa-user"></i>Martin
                    </li>
                  </ol>
                  <p>
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laborisnisi.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_cd_xs_we">
                <div className="_ol_xs_we">
                  <img src="assets/images/blog/7.jpg" />
                </div>
                <div className="_lo_de_we_xa">
                  <h2>Corrupted Because Of Crime</h2>
                  <ol>
                    <li>
                      <i className="fal fa-calendar-alt"></i>10-7-2020
                    </li>
                    <li>
                      <i className="far fa-user"></i>Martin
                    </li>
                  </ol>
                  <p>
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laborisnisi.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_cd_xs_we">
                <div className="_ol_xs_we">
                  <img src="assets/images/blog/8.jpg" />
                </div>
                <div className="_lo_de_we_xa">
                  <h2>Justice If You Are Innocent</h2>
                  <ol>
                    <li>
                      <i className="fal fa-calendar-alt"></i>10-7-2020
                    </li>
                    <li>
                      <i className="far fa-user"></i>Martin
                    </li>
                  </ol>
                  <p>
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laborisnisi.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <div className="_cd_xs_we">
                <div className="_ol_xs_we">
                  <img src="assets/images/blog/9.jpg" />
                </div>
                <div className="_lo_de_we_xa">
                  <h2>Crime Aware About Law</h2>
                  <ol>
                    <li>
                      <i className="fal fa-calendar-alt"></i>10-7-2020
                    </li>
                    <li>
                      <i className="far fa-user"></i>Martin
                    </li>
                  </ol>
                  <p>
                    consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laborisnisi.
                  </p>
                  <a href="#">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
