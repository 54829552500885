import React from "react";
import AboutLawyer from "./AboutLawyer";
import Business from "./Business";
import Other from "./Other";
import Slider from "./Slider";

function Home() {
  return (
    <>
      <Slider />
      <AboutLawyer />
      <Business />
      <Other />
    </>
  );
}

export default Home;
